import request from '../request'

//移库单列表
export const mvtry_bill_index_request = p => {
    return request({
        method:'GET',
        url:'mvtry_bill/index',
        params: p
    })
}

//添加移库单
export const mvtry_bill_add_request = d => {
    return request({
        method:'POST',
        url:'mvtry_bill/add',
        data: d
    })
}

//删除移库单
export const mvtry_bill_del_request = id => {
    return request({
        method:'DELETE',
        url:'mvtry_bill/del',
        data: {
            id: id
        }
    })
}

//修改移库单
export const mvtry_bill_edit_request = d => {
    return request({
        method:'PUT',
        url:'mvtry_bill/edit',
        data: d
    })
}

//获取移库单详情
export const mvtry_bill_details_request = id => {
    return request({
        method:'GET',
        url:'mvtry_bill/details',
        params: {
            id
        }
    })
}

//删除移库单中产品
export const mvtry_bill_del_product_request = product_id => {
    return request({
        method:'DELETE',
        url:'mvtry_bill/del_product',
        data: {
            product_id: product_id
        }
    })
}

// 提交移库单
export const mvtry_bill_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'mvtry_bill/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看移库单审批记录
export const mvtry_bill_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'mvtry_bill/sel_approval',
        params: {
            id
        }
    })
}

// 审批移库单
export const mvtry_bill_approval_request = d => {
    return request({
        method:'PUT',
        url:'mvtry_bill/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 撤回移库单
export const mvtry_bill_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'mvtry_bill/withdraw',
        data: {
            id: d.id
        }
    })
}