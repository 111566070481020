<template>
    <div v-loading.fullscreen.lock="loading">
        <mvtry-bill-search-bar-component
            add_auth="新增移库单"
            @search="get_mvtry_bill_index"
            @addTeam="addTeam"
        ></mvtry-bill-search-bar-component>
        <common-table-component
            details_auth="移库单详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_mvtry_bill"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_mvtry_bill_index"
        ></common-page-component>
        <mvtry-bill-edit-component
            :id="fid"
            :edit_auth="edit_auth"
            :del_auth="del_auth"
            :to_approval_auth="to_approval_auth"
            :sel_approval_auth="sel_approval_auth"
            :ApprovalAuth="ApprovalAuth"
            :withdraw_auth="withdraw_auth"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :mvtry_bill_details_data="fmvtry_bill_details_data"
            :fAllSatff="fAllSatff"
            @exitMvtryBillDialog="dialogExit"
            @search="get_mvtry_bill_index"
            @show_edit="show_edit"
            @refresh="refresh_details"
            @frefresh="frefresh"
        ></mvtry-bill-edit-component>
    </div>
</template>

<script>
import { mvtry_bill_index_request,mvtry_bill_details_request } from '@/network/WareHouse/MvtryBill.js'
import { staff_list_request } from '@/network/list.js'

import MvtryBillSearchBarComponent from '@/components/WareHouse/MvtryBill/MvtryBillSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import MvtryBillEditComponent from '@/components/WareHouse/MvtryBill/MvtryBillEditComponent'

export default {
    name:'MvtryBillView',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            cond: {
                state: '',
                name: '',
                entry_staff_id: '',
                mvtry_date: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            ftotal: 0,
            fmvtry_bill_details_data: {},
            fAllSatff: [],
            fcol_arr: [
                {
                    prop: 'mvtry_date',
                    label: '移库日期',
                    minWidth: '120px',
                },
                {
                    prop: 'name',
                    label: '移库单号',
                    minWidth: '120px',
                },{
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    }
                },{
                    prop: 'current_examine_staff_name',
                    label: '当前审批人',
                    minWidth: '100px',
                },{
                    prop: 'entry_staff_name',
                    label: '录单员',
                    minWidth: '100px',
                }
            ],
            edit_auth: false,
            del_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false
        }
    },
    computed:{},
    methods:{
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllSatff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_mvtry_bill_index(param = {}) {
            this.loading = true
            this.cond.state = param.state ?? this.cond.state
            this.cond.name = param.name ?? this.cond.name
            this.cond.entry_staff_id = param.entry_staff_id ?? this.cond.entry_staff_id
            this.cond.mvtry_date = param.mvtry_date ?? this.cond.mvtry_date
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            mvtry_bill_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_mvtry_bill(id) {
            this.fid = id
            this.fdialogFormVisible = true
            mvtry_bill_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fmvtry_bill_details_data = s.result
                        //权限判断
                        this.is_auth(s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        is_auth(res) {
            this.edit_auth = false
            this.del_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false

            if(res.state === 0) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交移库单')
                    this.edit_auth = this.$_has('修改移库单')
                    this.del_auth = this.$_has('删除移库单')
                }
            } else if(res.state === 1) {
                 //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回移库单')
                }
                //判断当前审批人是否为自己
                if(res.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批移库单')
                }
                this.sel_approval_auth = this.$_has('查看移库单审批记录')
            } else if(res.state === 2) {
                this.sel_approval_auth = this.$_has('查看移库单审批记录')
            } else if(res.state === 3) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交移库单')
                    this.edit_auth = this.$_has('修改移库单')
                    this.del_auth = this.$_has('删除移库单')
                }
                this.sel_approval_auth = this.$_has('查看移库单审批记录')
            }
        },
        refresh_details(id) {
            this.details_mvtry_bill(id)
        },
        frefresh() {
            this.details_mvtry_bill(this.fid)
            this.get_mvtry_bill_index()
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_mvtry_bill_index()
        this.get_all_staff()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        MvtryBillSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        MvtryBillEditComponent
    },
    watch:{}
}
</script>

<style lang='less'></style>