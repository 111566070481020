<template>
    <div>
        <el-dialog
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="80%"
            :center="true"
            top="1vh"
            :close-on-click-modal="false"
            :before-close="() => closeMvtryBillDialog('MvtryBillEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <template #title>
                <span style="font-weight: bold;">{{dialogFormTitle}}</span>
                <el-tag v-if="MvtryBill.state===0" type="info" size="mini">待提交</el-tag>
                <el-tag v-if="MvtryBill.state===1" size="mini">审批中</el-tag>
                <el-tag v-if="MvtryBill.state===2" type="success" size="mini">已通过</el-tag>
                <el-tag v-if="MvtryBill.state===3" type="danger" size="mini">已驳回</el-tag>
            </template>
            <el-form
                :model="MvtryBill"
                :rules="rules"
                ref="MvtryBillEditForm"
                label-width="100px"
                size="small"
                label-position="top"
            >
                <div v-if="show_type">
                    <div v-if="MvtryBill.mvtry_product.length>0">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">产品信息</legend>
                            <common-table-component
                                details_auth="删除移库单中产品"
                                button_name="删除"
                                table_size="mini"
                                table_height="300px"
                                :col_arr="fcol_arr"
                                :table_data="MvtryBill.mvtry_product"
                                @del_row="del_mvtry_stock"
                            ></common-table-component>
                        </fieldset>
                    </div>
                    <fieldset>
                        <legend class="legendc">其他信息</legend>
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item label="仓管员" prop="warehouse_staff_id">
                                    <el-select
                                        v-model="MvtryBill.warehouse_staff_id"
                                        filterable
                                        placeholder="选择仓管员"
                                        clearable
                                        size="small"
                                        class="clientc"
                                    >
                                        <el-option
                                            v-for="staff in fAllSatff"
                                            :key="staff.id"
                                            :label="staff.name"
                                            :value="staff.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="录单员" prop="entry_staff_id">
                                    <el-select
                                        v-model="MvtryBill.entry_staff_id"
                                        filterable
                                        placeholder="选择录单员"
                                        clearable
                                        size="small"
                                        class="clientc"
                                    >
                                        <el-option
                                            v-for="staff in fAllSatff"
                                            :key="staff.id"
                                            :label="staff.name"
                                            :value="staff.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="移库日期" prop="mvtry_date">
                                    <el-date-picker
                                    v-model="MvtryBill.mvtry_date"
                                        align="right"
                                        type="date"
                                        placeholder="选择日期"
                                        value-format="yyyy-MM-dd"
                                        class="mvtry_date"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </fieldset>
                </div>
                <div v-else>
                    <div v-if="MvtryBill.mvtry_product.length>0">
                        <fieldset class="fieldsetc">
                            <legend class="legendc">产品信息</legend>
                            <common-table-component
                                table_size="mini"
                                table_height="300px"
                                :col_arr="fcol_arr"
                                :table_data="MvtryBill.mvtry_product"
                            ></common-table-component>
                            <el-card>
                                <el-descriptions
                                    class="margin-top"
                                    :column="2"
                                    :labelStyle="{'width':'120px','text-align':'center'}"
                                    :contentStyle="{'text-align': 'center'}"
                                    border
                                >
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>合计移库重量</span>
                                        </template>
                                        <span>{{this.mvtry_weight | diy_mvtry_weight}}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item>
                                        <template slot="label">
                                            <span>合计移库托盘数</span>
                                        </template>
                                        <span>{{this.mvtry_tray_num | diy_mvtry_tray_num}}</span>
                                    </el-descriptions-item>
                                </el-descriptions>
                            </el-card>
                        </fieldset>
                    </div>
                    <fieldset>
                        <legend class="legendc">其他信息</legend>
                        <el-descriptions
                            class="margin-top"
                            :column="3"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>仓管员</span>
                                </template>
                                <span>{{MvtryBill.warehouse_staff_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>录单员</span>
                                </template>
                                <span>{{MvtryBill.entry_staff_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>移库日期</span>
                                </template>
                                <span>{{MvtryBill.mvtry_date}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </fieldset>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="to_approval_auth"
                        type="success"
                        plain
                        @click="to_approval('MvtryBillEditForm')"
                        >提交</el-button
                    >
                    <el-button
                        v-if="sel_approval_auth"
                        type="warning"
                        plain
                        @click="sel_approval()"
                        >查看审批</el-button
                    >
                    <el-button
                        v-if="withdraw_auth"
                        type="danger"
                        plain
                        @click="MvtryBillWithdraw('MvtryBillEditForm')"
                        >审批撤回</el-button
                    >
                    <el-button
                        v-if="edit_auth"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="del_auth && (MvtryBill.mvtry_product.length===0)"
                        type="danger"
                        plain
                        @click="MvtryBillDel('MvtryBillEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeMvtryBillDialog('MvtryBillEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('MvtryBillEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('MvtryBillEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeMvtryBillDialog('MvtryBillEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('MvtryBillEditForm')"
                        >确定修改</el-button
                    >
                    <el-button plain @click="closeMvtryBillDialog('MvtryBillEditForm')"
                        >取消操作</el-button
                    >
                </div>
                <common-approval-dialog
                    :id="id"
                    :ApprovalDialogVisible="ApprovalDialogVisible"
                    :data="ApprovalData"
                    :current_examine_staff_id="MvtryBill.current_examine_staff_id"
                    :ApprovalAuth="ApprovalAuth"
                    :ApprovalRequest="MvterBillApprovalRequest"
                    @exitApprovalDialog="exitApprovalDialog"
                    @refresh="$emit('frefresh')"
                >
                </common-approval-dialog>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { 
    mvtry_bill_add_request,
    mvtry_bill_del_request,
    mvtry_bill_edit_request,
    mvtry_bill_to_approval_request,
    mvtry_bill_sel_approval_request,
    mvtry_bill_approval_request,
    mvtry_bill_del_product_request,
    mvtry_bill_withdraw_request
} from '@/network/WareHouse/MvtryBill.js'
import { thousandBitSeparator,fomatFloat } from '@/assets/js/common.js'
import CommonTableComponent from '@/components/common/CommonTableComponent'

import CommonApprovalDialog from '@/components/common/CommonApprovalDialog'

export default {
    name:'MvtryBillEditComponent',
    data(){
        return {
            login_loading: false,
            MvtryBill: {
                warehouse_staff_id: '',
                entry_staff_id: this.$store.state.user.id,
                mvtry_date: '',
                mvtry_product: []
            },
            rules: {
                warehouse_staff_id: [
                    {
                        required: true,
                        message: ' 请选择仓管员',
                        trigger: 'blur',
                    },
                ],
                entry_staff_id: [
                    {
                        required: true,
                        message: ' 请选择录单员',
                        trigger: 'blur',
                    },
                ],
                mvtry_date: [
                    {
                        required: true,
                        message: ' 请选择移库日期',
                        trigger: 'blur',
                    },
                ]
            },
            fcol_arr: [
                {
                    prop: 'product_name',
                    label: '产品名称',
                    minWidth: '260px'
                },{
                    prop: 'code',
                    label: '产品型号',
                    minWidth: '150px'
                },{
                    prop: 'batch_number',
                    label: '订单号',
                    minWidth: '120px'
                },{
                    prop: 'specifications',
                    label: '规格',
                    minWidth: '100px'
                },{
                    prop: 'weight',
                    label: '移库重量(KG)',
                    minWidth: '120px',
                    editdata(row) {
                        return thousandBitSeparator(row['weight'])
                    }
                },{
                    prop: 'tray_num',
                    label: '移库托盘数(个)',
                    minWidth: '120px'
                },{
                    prop: 'before_localtion_id',
                    label: '当前所在位置',
                    minWidth: '300px',
                    html(row) {
                        let res = ''
                        if(row['before_enterprise_name'] === row['after_enterprise_name']) {
                            res += '<span>' + row['before_enterprise_name'] + '</span>'
                        } else {
                            res += '<span style="color:#FF0000">' + row['before_enterprise_name'] + '</span>'
                        }

                        res += '/'
                        if(row['before_storeroom_name'] === row['after_storeroom_name']) {
                            res += '<span>' + row['before_storeroom_name'] + '</span>'
                        } else {
                            res += '<span style="color:#FF0000">' + row['before_storeroom_name'] + '</span>'
                        }

                        res += '/'
                        if(row['before_localtion_name'] === row['after_localtion_name']) {
                            res += '<span>' + row['before_localtion_name'] + '</span>'
                        } else {
                            res += '<span style="color:#FF0000">' + row['before_localtion_name'] + '</span>'
                        }

                        return res
                    }
                },{
                    prop: 'after_localtion_id',
                    label: '移库目标位置',
                    minWidth: '300px',
                    html(row) {
                        let res = ''
                        if(row['before_enterprise_name'] === row['after_enterprise_name']) {
                            res += '<span>' + row['after_enterprise_name'] + '</span>'
                        } else {
                            res += '<span style="color:#00BB00">' + row['after_enterprise_name'] + '</span>'
                        }

                        res += '/'
                        if(row['before_storeroom_name'] === row['after_storeroom_name']) {
                            res += '<span>' + row['after_storeroom_name'] + '</span>'
                        } else {
                            res += '<span style="color:#00BB00">' + row['after_storeroom_name'] + '</span>'
                        }

                        res += '/'
                        if(row['before_localtion_name'] === row['after_localtion_name']) {
                            res += '<span>' + row['after_localtion_name'] + '</span>'
                        } else {
                            res += '<span style="color:#00BB00">' + row['after_localtion_name'] + '</span>'
                        }

                        return res
                    }
                },{
                    prop: 'note',
                    label: '备注',
                    minWidth: '200px',
                    show_overflow_tooltip: true
                }
            ],
            ApprovalData: [],
            ApprovalDialogVisible: false,
            MvterBillApprovalRequest:mvtry_bill_approval_request
        }
    },
    computed:{
        dialogFormTitle() {
            if(this.show_type === 0) {
                return this.MvtryBill.name ? '移库单：' + this.MvtryBill.name : '移库单详情'
            } else if (this.show_type === 1) {
                return '添加移库单'
            } else {
                return '修改移库单'
            }
        },
        mvtry_weight() {
            return this.MvtryBill.mvtry_product.reduce( (s,cur) => {
                return s + cur.weight * 1
            },0)
        },
        mvtry_tray_num() {
            return this.MvtryBill.mvtry_product.reduce( (s,cur) => {
                return s + cur.tray_num * 1
            },0)
        },
    },
    methods:{
        closeMvtryBillDialog() {
            this.reset_data()
            this.$emit('exitMvtryBillDialog')
        },
        resetForm() {
            this.reset_data()
        },
        reset_data() {
            this.MvtryBill = {
                warehouse_staff_id: '',
                entry_staff_id: this.$store.state.user.id,
                mvtry_date: '',
                mvtry_product: []
            }
        },
        MvtryBillDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    mvtry_bill_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeMvtryBillDialog(formName))
                })
                .catch((err) => {})
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.MvtryBillInsert(formName)
            })
        },
        MvtryBillInsert(formName) {
            this.login_loading = true
            mvtry_bill_add_request(this.MvtryBill)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitMvtryBillDialog')
                        this.$emit('search')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
                .finally(() => this.closeMvtryBillDialog(formName))
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.MvtryBillEdit(formName)
            })
        },
        MvtryBillEdit(formName) {
            this.login_loading = true
            this.MvtryBill.id = this.id
            mvtry_bill_edit_request(this.MvtryBill)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitMvtryBillDialog')
                        this.$emit('search')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
                .finally(() => this.closeMvtryBillDialog(formName))
        },
        del_mvtry_stock(product_id) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
            .then(() => {
                this.login_loading = true
                mvtry_bill_del_product_request(product_id)
                    .then((s) => {
                        this.login_loading = false
                        if (s.status === 0) {
                            this.$message.success('删除成功!')
                            this.$emit('refresh',this.id)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        this.login_loading = false
                        this.$message.error(err)
                    })
            })
            .catch((err) => {})
        },
        to_approval(formName) {
            if(this.MvtryBill.mvtry_product.length===0) {
                this.$message.error('未添加移库产品!')
                return
            }

            this.$prompt('请填写申请原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.login_loading = true
                    mvtry_bill_to_approval_request({id:this.id,note:value})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                    this.$message.success('提交成功!')
                                    this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                        .finally(() => this.closeMvtryBillDialog(formName))
                }).catch(()=>{});
        },
        exitApprovalDialog() {
            this.ApprovalDialogVisible = false
        },
        sel_approval() {
            this.login_loading = true
            mvtry_bill_sel_approval_request(this.id)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.ApprovalData = s.result
                        this.ApprovalDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        MvtryBillWithdraw() {
            this.$confirm(
                '是否确定撤回？（请谨慎操作）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.login_loading = true
                    mvtry_bill_withdraw_request({id:this.id})
                        .then((s) => {
                            this.login_loading = false
                            if (s.status === 0) {
                                this.$message.success('已撤回!')
                                this.$emit('frefresh')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.login_loading = false
                            this.$message.error(err)
                        })
                })
                .catch((err) => {})
        }
    },
    filters:{
        diy_mvtry_weight(e) {
            return thousandBitSeparator(fomatFloat(e)) +' KG'
        },
        diy_mvtry_tray_num(e) {
            return thousandBitSeparator(e) +' 个'
        }
    },
    props:{
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        mvtry_bill_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        fAllSatff: Array,
        edit_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        del_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        to_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        sel_approval_auth: {
            type:Boolean,
            default() {
                return false
            }
        },
        ApprovalAuth: {
            type:Boolean,
            default() {
                return false
            }
        },
        withdraw_auth: {
            type:Boolean,
            default() {
                return false
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        CommonTableComponent,
        CommonApprovalDialog
    },
    watch:{
        mvtry_bill_details_data(newVal) {
            this.MvtryBill = newVal
        },
    }
}
</script>

<style lang='less'>
.clientc {
    width: 100%;
}
.mvtry_date.el-date-editor.el-input {
    width: 100%;
}
.fieldsetc {
    margin-bottom: 20px;
    min-width: 95%;
    min-width: border-box;
}
.legendc {
    color: #921AFF;
}
</style>